import { useEffect, useState } from 'react';

import { useShow } from "@pankod/refine-core";

import { Button, Col, Row, Show, Typography } from "@pankod/refine-antd";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import logo from '../../assets/logo.jpeg';
import './styles.css';

const { Title, Text } = Typography;


function numberFormat(value, decimalPositions, decimalSeparator, thousandsSeparator){

	decimalPositions = isNaN(decimalPositions = Math.abs(decimalPositions)) ? 2 : decimalPositions;
    decimalSeparator = decimalSeparator === undefined ? "." : decimalSeparator;
    thousandsSeparator = thousandsSeparator === undefined ? "," : thousandsSeparator;
    let sign = value < 0 ? "-" : "";
    let i = String(parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalPositions)));
	let k = i.length;
    let j = k > 3 ? k % 3 : 0;

   return sign + (j ? i.substr(0, j) + thousandsSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) + (decimalPositions ? decimalSeparator + Math.abs(value - i).toFixed(decimalPositions).slice(2) : "");

}



export const CalificacionesShow = () => {

	const userIdentity = localStorage.getItem('identity');
	let identity;

    if(userIdentity){

        identity = JSON.parse(userIdentity);

    }

    const [ alumnoRecord, setAlumnoRecord ] = useState(undefined);


    const { queryResult } = useShow({
		id: identity.id
	});

	const { data, isLoading } = queryResult;
	const record = data?.data;

	const [ displayPDF, setDisplayPDF ] = useState(false);


    record && record.calificaciones.sort((a, b) => a.tetramestre > b.tetramestre ? -1 : a.tetramestre < b.tetramestre ? 1 : 0);


    const tetras = [
        { 1: 'PRIMER' },
        { 2: 'SEGUNDO' },
        { 3: 'TERCER' },
        { 4: 'CUARTO' },
        { 5: 'QUINTO' },
        { 6: 'SEXTO' }
    ];


    let sumGral, promedioGral;

    if(record){

        sumGral = record.calificaciones.reduce((prev, current) => { return { calificacion: prev.calificacion + parseFloat(current.calificacion) }}, { calificacion: 0 });

        promedioGral = numberFormat(sumGral.calificacion / record.calificaciones.length, 2);

    }


    useEffect(() => {

    	if(record?.alumno.nombre !== alumnoRecord?.nombre){

    		setAlumnoRecord(record);

    	}

    }, [data]);


	function downloadPdf(){

		setDisplayPDF(true);

	}

	useEffect(() => {

		function setDisplayPDFToFalse(){

			setDisplayPDF(false);

			return ;

		}

		if(displayPDF){

			html2canvas(document.querySelector("#calficacionesPDF"))
			.then(canvas => {

				setDisplayPDFToFalse();

		        const imgData = canvas.toDataURL('image/png');
		        const pdf = new jsPDF({
					orientation: "portrait",
					unit: "in",
					format: [8.5, 11]
				});

		        pdf.addImage(imgData, 'PNG', 0, 0, 8.5, 11);
		        pdf.save(`Calificaciones-${record?.alumno.nombre.replaceAll(' ', '_')}.pdf`);
	    	});

		}

	});


	function renderPdf(){

		const now = new Date();

		return (

			<div id="calficacionesPDF" style={{ fontSize: '0.55rem', height: '11in', maxHeight: '11in', width: '8.5in', maxWidth: '8.5in', padding: '2rem 1.2rem' }}>

				<Row gutter={ [ 30, 30 ] }>
					<Col span={ 4 } style={{ textAlign: 'left' }}>
						<img
							src={ logo }
							alt="Liceo Morelia"
							style={{
								display: "flex",
								alignItems: "left",
								marginLeft: "0px",
								padding: "0",
								width: "120px"
							}}
						/>
					</Col>

					<Col span={ 16 } style={{ textAlign: 'center' }}>
						<Title level={ 5 }>Liceo morelia</Title>
					</Col>
				</Row>

				<Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>
					<Col span={ 5 }>
						<Text strong>{ record?.alumno.nombre }<br/></Text>
						<Text>Matr&iacute;cula { record?.alumno.matricula }<br/></Text>
					</Col>
					<Col span={ 12 }>
						<Text>Grupo { record?.alumno.grupo }</Text>
					</Col>
					<Col span={ 7 } style={{ textAlign: 'right' }}>
						<Text>{ now.getDate() } / { now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1 } / { now.getFullYear() }</Text>
					</Col>
				</Row>

				<Row gutter={ [20, 48] }>

					{
						record && tetras.map(renderTetra)
					}

				</Row>

				<Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>

	                <Col className="centered" span={ 24 }>
	                    <Title level={ 5 } style={{ display: 'inline-block' }}>Promedio general: </Title>  &nbsp;  <Title level={ 4 } style={{ display: 'inline-block' }}>{ promedioGral } </Title>
	                </Col>

	            </Row>

				<Row>
					<Col>
						<Text>Documento de car&aacute;cter informativo. No v&aacute;lido sin sello de la instituci&oacute;n</Text>
					</Col>
				</Row>

			</div>

		);

	}




    function renderTetra(tetra){

        const keys = Object.keys(tetra);

        const materias = record.calificaciones.filter(r => r.tetramestre === parseInt(keys[0]));

        if(materias.length === 0){
            return null;
        }


        const sum = materias.reduce((prev, current) => { return { calificacion: prev.calificacion + parseFloat(current.calificacion) }}, { calificacion: 0 });
        const promedio = numberFormat(sum.calificacion / materias.length, 2);


        return (

            <Col key={ `tetra_${ keys[0] }` } sm={ 12 } style={{ borderBottom: 'solid 1px #ccc' }}>

                <Row gutter={ [{ xs: 1, sm: 5 }, 8] }>

                    <Col className="centered" span={ 24 }>
                        <Title level={ 5 }>{ tetra[keys[0]] } CUATRIMESTRE</Title>
                    </Col>

                    <Col span={ 12 }>
                        <Text strong>Materia</Text>
                    </Col>

                    <Col className="centered" span={ 4 }>
                        <Text strong>Calificaci&oacute;n</Text>
                    </Col>
                    <Col className="centered" span={ 5 }>
                        <Text strong>Per&iacute;odo</Text>
                    </Col>
                    <Col className="centered" span={ 3 }>
                        <Text strong>Faltas</Text>
                    </Col>

                </Row>


                {
                    materias.map((recMateria, materiaIndex) =>

                        <Row className={ materiaIndex % 2 && 'alt' } gutter={ [12, 0] } key={ `materia_${ recMateria.materia }` }>

                            <Col span={ 12 }>
                                <Text >{ recMateria.materia }</Text>
                            </Col>

                            <Col className="centered" span={ 4 }>
                                <Text strong>{ numberFormat(parseFloat(recMateria.calificacion), 1) }</Text>
                            </Col>
                            <Col className="centered" span={ 5 }>
                                <Text >{ recMateria.periodo.substring(0, 3).toUpperCase() }</Text>
                            </Col>
                            <Col className="centered" span={ 3 }>
                                <Text >{ recMateria.faltas }</Text>
                            </Col>

                        </Row>

                    )

                }


                <Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>
                    <Col className="centered" span={ 24 }>
                        <Text>Promedio: </Text>
                        <Text strong> { promedio } </Text>
                    </Col>
                </Row>

            </Col>
        )


    }


    return (

		<Show isLoading={ isLoading } title={ record?.alumno.nombre } pageHeaderProps={{ backIcon: false, extra: <Button onClick={ () => downloadPdf() } style={{ backgroundColor: '#A47F28', color: '#eeeeee' }} disabled={ displayPDF }>Descargar</Button>, subTitle: `${ record?.alumno.matricula } - Grupo ${ record?.alumno.grupo }` }} >

            <Row gutter={ [{ sm: 0, md: 80 }, 48] }>

                {
                    alumnoRecord && tetras.map(renderTetra)
                }

            </Row>

            <Row style={{ marginBottom: '0.7rem', marginTop: '1rem' }}>

                <Col className="centered" span={ 24 }>
                    <Title level={ 5 } style={{ display: 'inline-block' }}>Promedio general: </Title>  &nbsp;  <Title level={ 4 } style={{ display: 'inline-block' }}>{ promedioGral } </Title>
                </Col>

            </Row>

			<Row>

			</Row>

			<Row>
				{
					displayPDF && renderPdf()
				}
			</Row>

        </Show>

    )

}
